import React from 'react';
import jwt from 'jwt-decode';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import LogInForm from '../../components/LoginForm';
import { signIn } from '../../actions/auth-actions';
import { authenticate } from '../../services/auth-service';
import Alert from '../../components/Alerts';

let alertError = <span style={{color:'white'}}>lalalala</span>;

class LoginPage extends React.Component {
    
    constructor() {
        super();

        this.state = {
            alert: false,
            alertType: '',
            alertMessage: '',
            alertTitle: ''
        };

        this.handler = this.handler.bind(this);
    }

    async signIn(formData) {
        
        if(formData.email && formData.password){
        
            try {
                const resAuth = await authenticate(formData);
                if(resAuth){

                    if(resAuth.status === 1){

                        if(resAuth.token){

                            const token = resAuth.token;
                            localStorage.setItem('jwt', token);
                            const tokenData = jwt(token);
                            this.props.signIn({ jwt:token, admin: tokenData.admin });
                        
                        }else{
                            console.log(resAuth);
                        }

                    }else{
                        this.setState({
                            alert: true,
                            alertType: 'danger',
                            alertMessage: resAuth.message,
                            alertTitle: 'Error!'
                        });
                    }

                    // UPDATE STORE
                }else{
                    
                    this.setState({
                        alert: true,
                        alertType: 'danger',
                        alertMessage: 'Unknow error',
                        alertTitle: 'Error!'
                    });
                    
                }

            } catch (error) {
                
                
            }

        }

    }

    showAlert() {
        if(this.state.alert){
            return (
                <Alert color={this.state.alertType} handler={this.handler} >
                    <p>
                        <span className="bold-text">{this.state.alertTitle}</span> {this.state.alertMessage}
                    </p>
                </Alert>);
        }
        return '';
    }

    handler() {
        this.setState({ alert: false });
    }
    
    render() { 

        if(this.props.auth){
            return <Redirect to="/" />
        }

        return (
            <div className="theme-dark ltr-support" dir="ltr">
                <div className="wrapper">
                    <main>
                        <div className="account">
                            <div className="account__wrapper">
                                <div className="account__card">
                                    <div className="account__head">
                                        <h3 className="account__title">Admin
                                            <span className="account__logo"> Albor
                                                <span className="account__logo-accent">DEV</span>
                                            </span>
                                        </h3>
                                    </div>
                                    {this.showAlert()}
                                    <LogInForm onSubmit={this.signIn.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        auth: state.auth.isLogged, 
        jwt: state.auth.jwt 
    }
}

export default connect(mapStateToProps, { signIn })(LoginPage);