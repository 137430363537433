import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';

const WidgetBars = (props) => {

    console.log('WIDGET');
    console.log(props);
    
    let { t, title, activeIndex, handleClick, color, data, icon } = props;

    activeIndex = activeIndex || 0;
    color = color || '#f6da6e';
    const activeItem = data[activeIndex];
    
    return (
        <Col md={12} xl={3} lg={6} xs={12}>
            <Card>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text">
                            {t(`dashboard.${title}`)}
                            <span className="floatRightCount">77</span>
                        </h5>
                    </div>
                    <div className="dashboard__total">
                        <TrendingUpIcon className="dashboard__trend-icon" />
                        <p className="dashboard__total-stat">
                            {(activeItem.pv)}
                        </p>
                        <div className="dashboard__chart-container">
                            <ResponsiveContainer height={50}>
                                <BarChart data={data}>
                                    <Bar dataKey="pv" onClick={handleClick}>
                                        {
                                            data.map((entry, index) => (
                                            <Cell
                                                cursor="pointer"
                                                fill={index === activeIndex ? '#4ce1b6' : color}
                                                key={`cell-${index}`}
                                            />))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );

}

export default withTranslation('common')(WidgetBars);

