import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { signOut } from '../../../actions/auth-actions';

import SidebarLink from './SidebarLink.js';

class SidebarContent extends Component {
    // static propTypes = {
    //     onClick: PropTypes.func.isRequired,
    // };

    logout() {
        this.props.signOut();
    }

    render() {
        
        return (
            <div className="sidebar__content">
                <ul className="sidebar__block">
                    {/* LIST LAYOUT ROUTES */}
                    <SidebarLink title="Home" route="/" icon="home" newLink={true} />
                    <SidebarLink title="Customers" route="/customers" icon="users" newLink={true} />
                    <SidebarLink title="Users" route="/users" icon="user" newLink={true} />
                    <SidebarLink title="Support" route="/support" icon="bug" newLink={true} />
                    <SidebarLink title="Plans" route="/plans" icon="gift" />
                    {/* <SidebarLink title="Log Out" icon="exit" onClick={this.logout()} /> */}
                    
                    {/* <SidebarCategory title="Example Pages....." icon="diamond">
                        <SidebarLink title="Page one" route="/pages/one" onClick={this.hideSidebar} />
                        <SidebarLink title="Page two" route="/pages/two" onClick={this.hideSidebar} />
                    </SidebarCategory> */}
                </ul>
            </div>
        );
    }

}


export default connect(null, { signOut })(SidebarContent);
