import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import themeReducer from './theme-reducer';
import sidebarReducer from './sidebar-reducer';
import rtlReducer from './rtl-reducer';
import authReducer from './auth-reducer';
import loadingReducer from './loading-reducer';
import homeReducer from './home-reducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  home: homeReducer,
  loading: loadingReducer
});

export default reducer;