import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';
import { signOut } from '../../../actions/auth-actions';

class TopbarProfile extends PureComponent {
    constructor() {
        super();
        this.state = {
            collapse: false,
        };
    }

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    logoutFunction = () => {
        localStorage.setItem('jwt', null);
        this.props.signOut();
    }

    render() {

        const { collapse } = this.state;
        const { name, image } = this.props;

        return (
            <div className="topbar__profile">
                <button type="button" className="topbar__avatar" onClick={this.toggle}>
                    <img className="topbar__avatar-img" src={image} alt="avatar" />
                    <p className="topbar__avatar-name">{name}</p>
                    <DownIcon className="topbar__icon" />
                </button>
                {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
                <Collapse isOpen={collapse} className="topbar__menu-wrap">
                    <div className="topbar__menu">
                        <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
                        <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
                        <div className="topbar__menu-divider" />
                            <Link className="topbar__link" to="/login" onClick={this.logoutFunction}>
                                <span className={`topbar__link-icon lnr lnr-exit`} />
                                <p className="topbar__link-title">Log Out</p>
                            </Link>
                        </div>
                </Collapse>
            </div>
        );
    }

}

// export default TopbarProfile;
export default connect(null, { signOut })(TopbarProfile);