import { HOME } from '../actions/home-actions';
  
const initialState = {
    
    customers: [],
    users: [],
    subscriptions: [],
    tickets: [],
    plans: [],
    stadistics: {}

};

export default (state = initialState, action) => {
    switch(action.type) {
        case HOME:
            return { ...state, 
                customers: action.payload.customers, 
                users: action.payload.users, 
                subscriptions: action.payload.subscriptions,
                tickets: action.payload.tickets,
                plans: action.payload.plans,
                stadistics: action.payload.stadistics,
            };
        default:
            return state;
    }
}