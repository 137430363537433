/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const dataMonths = [
    { name: 'Diciembre', uv: 0 },
    { name: 'Enero', uv: 0 },
    { name: 'Febrero', uv: 2 },
    { name: 'Marzo', uv: 4 },
    { name: 'Abril', uv: 6 },
    { name: 'Mayo', uv: 5 },
    { name: 'Total', uv: 5 }
];

class Customers extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    handleClick = (item) => {
        const index = dataMonths.indexOf(item.payload);
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const activeIndex = this.state ? this.state.activeIndex : this.props.activeIndex;
        const activeItem = dataMonths[activeIndex];
        const { t } = this.props;

        return (
            <Col md={12} xl={3} lg={6} xs={12}>
                <Card>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text">
                                {t('dashboard.customers')} 
                                <span className="floatRightCount">{this.props.customers.totalDocs}</span>
                            </h5>
                        </div>
                        <div className="dashboard__total">
                            <TrendingUpIcon className="dashboard__trend-icon" />
                            <p className="dashboard__total-stat">
                                {activeItem.uv}
                            </p>
                            <div className="dashboard__chart-container">
                                <ResponsiveContainer height={50}>
                                    <BarChart data={dataMonths}>
                                        <Bar dataKey="uv" onClick={this.handleClick}>
                                            {dataMonths.map((entry, index) => (
                                                <Cell
                                                    cursor="pointer"
                                                    fill={index === activeIndex ? '#4ce1b6' : '#ff4861'}
                                                    key={`cell-${index}`}
                                                />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = (state) => { return { customers: state.home.customers, activeIndex: 5 } }
export default compose(withTranslation('common'), connect(mapStateToProps, {}))(Customers);
