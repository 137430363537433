import { CHANGE_LOADING, CHANGE_LOADED } from '../actions/loading-actions';
  
const initialState = {
    loaded: false, loading: true,
};

export default (state = initialState, action) => {

    switch(action.type) {
        case CHANGE_LOADING:
            return { ...state, loading: action.payload };
        case CHANGE_LOADED:
            return { ...state, loaded: action.payload };
        default:
            // console.log('DEFAULT')
            // console.log(action.type);
            return state;
    }
}
  
