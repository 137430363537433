import _ from 'lodash';
import { auth } from '../config/api';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';


export const isLogged = (jwt) => async dispatch => {
    const response = await auth.get('/isValid');
    dispatch({ type: 'AUTH', payload: response.data })
};

// export const signIn = (email, password) => async dispatch => {
//     const response = await auth.post(`/`, {email, password});
//     dispatch({ type: 'AUTH', payload: response.data })
// };

export const signIn = (data) => {
    return {
        type: SIGN_IN,
        payload: {
            jwt: data.jwt,
            admin: data.admin
        }
    };
};

// export const signOut = (id) => async dispatch => {
//     const response = await jsonPlaceholder.get(`/users/${id}`);
//     dispatch({ type: 'FETCH_USER', payload: response.data })
// };

export const signOut = () => {

    console.log(`AUTH-ACTION -> SIGN_OUT:${SIGN_OUT}`);
    return {
        type: SIGN_OUT
    };
};
