import axios from 'axios';

const URL_MICROSERVICE_AUTH = 'http://localhost:3003';
const URL_MICROSERVICE_API = 'http://localhost:3002';
const URL_MICROSERVICE_PATIENTS = 'http://localhost:3001';
const URL_MICROSERVICE_NOTIFICATIONS = 'http://localhost:3000';
const URL_MICROSERVICE_PLANS = 'http://localhost:3000';

export const auth = axios.create({
    baseURL: URL_MICROSERVICE_AUTH
});

export const api = axios.create({
    baseURL: URL_MICROSERVICE_API
});

export const patients = axios.create({
    baseURL: URL_MICROSERVICE_PATIENTS
});

export const notifications = axios.create({
    baseURL: URL_MICROSERVICE_NOTIFICATIONS
});

export const plans = axios.create({
    baseURL: URL_MICROSERVICE_PLANS
});

console.log(process.env);
