import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


import Layout from './Layout';
// import LoginPage from '../pages/LoginPage';
import HomePage from '../pages/HomePage';
import CustomersPage from '../pages/CustomersPage';
// import Users from '../pages/Users';

const Pages = () => (
    <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/customers" component={CustomersPage} />
    </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route path="/" component={Pages} />
        </div>
    </div>
);


const Router = ({ admin }) => {

    return (
        <div className="theme-dark ltr-support" dir="ltr">
            <div className="wrapper">
                <main>
                    <Switch>
                        <Route path="/" component={wrappedRoutes} />
                    </Switch>
                </main>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { admin: state.auth.admin };
} 

export default connect(mapStateToProps)(Router);

