import { SIGN_IN, SIGN_OUT } from '../actions/auth-actions';
  
const initialState = {
    isLogged: false,
    jwt: localStorage.getItem('jwt') || null,
    admin: null
};

export default (state = initialState, action) => {

    switch(action.type) {
        case SIGN_IN:
            return { ...state, isLogged: true, jwt: action.payload.jwt, admin: action.payload.admin };
        case SIGN_OUT:
            return { ...state, isLogged: false, jwt: null, admin: null };
        default:
            return state;
    }
}