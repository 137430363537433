
import { auth } from '../config/api';

export const authenticate = async (data) => {

    try {
        const response = await auth.post('/admin', data);
        return response.data;
    } catch (err) {   
        if (err.response) {
            return err.response.data;
        } else {
            return false;
        }
    }

}

export const validateToken = async (jwt) => {
    try {
        const response = await auth.post('/validate-admin', {jwt});
        return response.data;
    } catch (err) {   
        if (err.response) {
            return err.response.data;
        } else {
            return false;
        }
    }
}