export const CHANGE_LOADING = 'CHANGE_LOADING';
export const CHANGE_LOADED = 'CHANGE_LOADED';


export const changeLoading = (state) => {
    return {
        type: CHANGE_LOADING,
        payload: state
    };
}

export const changeLoaded = (state) => {
    return {
        type: CHANGE_LOADED,
        payload: state
    };
}
  
// export const changeLoading = (email, password) => async dispatch => {
//     const response = await auth.post(`/`, {email, password});
//     dispatch({ type: 'AUTH', payload: response.data })
// };

// export const signIn = (id) => async dispatch => {
//     const response = await jsonPlaceholder.get(`/users/${id}`);
//     dispatch({ type: 'FETCH_USER', payload: response.data })
// };
