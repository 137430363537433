import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

class Layout extends Component {
    

    render() {

        const { admin } = this.props;
        const layoutClass = classNames({
            layout: true,
            'layout--collapse': false,
        });
        
        return (
            <div className={layoutClass}>
                <Topbar name={`${admin.firstName} ${admin.lastName}`} image={admin.image} />
                <Sidebar />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { admin: state.auth.admin }
}

export default connect(
    mapStateToProps, 
    { }
)(Layout);