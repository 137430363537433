import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
// import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

const Sidebar = () => {

    const sidebarClass = classNames({
        sidebar: true,
        'sidebar--show': true,
        'sidebar--collapse': false,
    });

    return (
        <div className={sidebarClass}>
            <Scrollbar className="sidebar__scroll scroll">
                <div className="sidebar__wrapper sidebar__wrapper--desktop">
                <SidebarContent
                    onClick={() => console.log('CLICK!')}
                />
                </div>
                <div className="sidebar__wrapper sidebar__wrapper--mobile">
                <SidebarContent />
                </div>
            </Scrollbar>
        </div>
    );

};

export default Sidebar;
