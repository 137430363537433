import 'bootstrap/dist/css/bootstrap.css';
import '../scss/app.scss';

import React, { Component, Fragment } from 'react';

// import socketIOClient from 'socket.io-client';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { changeLoading, changeLoaded } from '../actions/loading-actions';
import { signIn, signOut } from '../actions/auth-actions';

import { validateToken } from '../services/auth-service';

import Router from './Router';
import LoginPage from '../pages/LoginPage';

// TEST TRANLATIONS
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { config as i18nextConfig } from '../translations';
i18next.init(i18nextConfig);
// ################

// const ENDPOINT = 'http://192.168.0.11:4001/';

class App extends Component {

    componentDidMount() {
        let status = false;
        if(this.props.jwt && !this.props.auth && !this.props.admin){
            this.validateToken(this.props.jwt);
        }else{
            this.props.changeLoaded(!status);
            this.props.changeLoading(status);
        }
    }

    async validateToken(jwt) {
        
        const isValid = await validateToken(jwt);
        if(isValid && isValid.status === 1 && isValid.jwt && isValid.admin){
            this.props.signIn({jwt:isValid.jwt, admin: isValid.admin });
        }else{
            localStorage.clear();
        }
        this.props.changeLoaded(true);
        this.props.changeLoading(false);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    }

    logout() {
        
        localStorage.clear();
        this.props.signOut();
        
    }


    render() {
        
        const { loaded, loading, auth, admin, jwt } = this.props;
        if(jwt && !auth){
            this.validateToken(jwt);
        }

        // const socket = socketIOClient(ENDPOINT);
        // console.log(socket);
        // socket.on('FromAPI', data => {
        //     console.log(`DATA SOCKET ${JSON.stringify(data)}`);
        // });
        
        return (
            <BrowserRouter>
                {/* <ScrollToTop> */}
                <Fragment>
                    <I18nextProvider i18n={i18next}>
                        {!loaded
                            && (
                            <div className={`load${loading ? '' : ' loaded'}`}>
                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                            )
                        }
                        <div>
                            {(auth) 
                            ? <Router />
                            : <Redirect to="/login" />
                            }
                            <Route exact path="/login" component={LoginPage} />
                            
                        </div>
                    </I18nextProvider>
                </Fragment>
                {/* </ScrollToTop> */}
            </BrowserRouter>
        );
    }
}

// export default App;
const mapStateToProps = (state) => {
    return { 
        loading: state.loading.loading, 
        loaded: state.loading.loaded, 
        auth: state.auth.isLogged, 
        admin: state.auth.admin, 
        jwt: state.auth.jwt 
    }
}

export default connect(
    mapStateToProps, 
    { changeLoading, changeLoaded, signIn, signOut }
)(App);