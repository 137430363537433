import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarNotification from './TopbarNotification';
import TopbarMail from './TopbarMail';
import TopbarProfile from './TopbarProfile';
// import TopbarLanguage from './TopbarLanguage';

class Topbar extends PureComponent {

    render() {
        
        return (
            <div className="topbar">
                <div className="topbar__wrapper">
                    <div className="topbar__left">
                        <TopbarSidebarButton />
                        <Link className="topbar__logo" to="/" />
                    </div>
                    <div className="topbar__right">
                        {/* <TopbarSearch /> */}
                        <TopbarNotification />
                        <TopbarMail new />
                        <TopbarProfile name={this.props.name} image={this.props.image} />
                        {/* <TopbarLanguage /> */}
                    </div>
                </div>
            </div>
        );
    
    }

}

export default Topbar;
