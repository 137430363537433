import React, { PureComponent } from 'react';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

class TopbarSidebarButton extends PureComponent {
    
    render() {
        
        return (
            <div>
                <button type="button" className="topbar__button topbar__button--desktop">
                    <img src={icon} alt="" className="topbar__button-icon" />
                </button>
                <button type="button" className="topbar__button topbar__button--mobile">
                    <img src={icon} alt="" className="topbar__button-icon" />
                </button>
            </div>
        );
    }

}

export default TopbarSidebarButton;
