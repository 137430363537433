import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getDataHome } from '../../actions/home-actions';

import Customers from '../../components/Dashboard/Customers';
import Users from '../../components/Dashboard/Users';
import Subscriptions from '../../components/Dashboard/Subscriptions';
import Tickets from '../../components/Dashboard/Tickets';

import WidgetBars from '../../components/Widget/index';

class HomePage extends React.Component {

    componentDidMount() {
        
        this.props.getDataHome(this.props.jwt);
        
    }


    renderWidgets() {

        const { user } = this.props;

        switch(user.type){
            case 'super':
            case 'admin':
            return (
                <Fragment>
                    <Customers />
                    <Users />
                    <Subscriptions />
                    <Tickets />
                    {/* <WidgetBars 
                        title="customers" 
                        color='#ED5865'
                        activeIndex={2}
                        handleClick={console.log()}
                        data={[
                            { name: 'Page A', pv: 561 },
                            { name: 'Page B', pv: 452 },
                            { name: 'Page C', pv: 154 },
                            { name: 'Page D', pv: 256 },
                            { name: 'Page E', pv: 545 },
                            { name: 'Page F', pv: 438 },
                            { name: 'Page G', pv: 523 },
                            { name: 'Page H', pv: 226 },
                            { name: 'Page I', pv: 548 },
                            { name: 'Page J', pv: 354 },
                        ]}
                         /> */}
                </Fragment>
            );
            case 'suppor':
            return (
                <Fragment>
                    <Tickets />
                </Fragment>
            );
            case 'seller':
            return (
                <Fragment>
                    <Customers />
                    <Users />
                    <Subscriptions />
                </Fragment>
            );
        }
        
    }

    render() {

        const { t } = this.props;
        
        return (
            <Container className="dashboard">
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">{t('dashboard.page_title')}</h3>
                    </Col>
                </Row>
                <Row> 
                    {/* WIDGETS */}
                    { this.renderWidgets() }
                </Row>
                <Row>
                    {/* <ABTestingAnalytics dir={rtl.direction} />
                    <BounceRateArea dir={rtl.direction} />
                    <VisitorsSessions dir={rtl.direction} />
                    <SalesStatistic />
                    <BudgetStatistic />
                    <AudienceByCountry />
                    <BestSellingRegions />
                    <GoalsCompletion /> */}
                </Row>
            </Container>
        )
    }

}

HomePage.propTypes = { t: PropTypes.func.isRequired };

const mapStateToProps = (state) => {
    return {
        jwt: state.auth.jwt,
        rtl: state.rtl,
        home: state.home,
        user: state.auth.admin
    }
}

export default compose(withTranslation('common'), connect(mapStateToProps, { getDataHome }))(HomePage);



